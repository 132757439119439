import { defineMessages } from "react-intl";

export default defineMessages( {
	individualSubscriptions: {
		id: "subscriptions.overview.individualSubscriptions",
		defaultMessage: "Individual subscriptions",
	},
	subscriptions: {
		id: "subscriptions.overview.subscriptions",
		defaultMessage: "Subscriptions",
	},
	gotoProvisioner: {
		id: "subscriptions.overview.gotoProvisioner",
		defaultMessage: "{ name }",
	},
	provisioned: {
		id: "subscriptions.overview.provisioned",
		defaultMessage: "Partner subscriptions",
	},
	boughtVia: {
		id: "subscriptions.overview.provisionedBy",
		defaultMessage: "Bought via",
	},
	status: {
		id: "subscriptions.overview.status",
		defaultMessage: "Status",
	},
	level: {
		id: "subscriptions.overview.level",
		defaultMessage: "Level",
	},
	used: {
		id: "subscriptions.overview.used",
		defaultMessage: "Used on",
	},
	billingType: {
		id: "subscriptions.overview.billingType",
		defaultMessage: "Billing type",
	},
	nextPaymentOn: {
		id: "subscriptions.overview.nextPaymentOn",
		defaultMessage: "Next billing: ",
	},
	endsDate: {
		id: "ends.date",
		defaultMessage: "Ends on { endDate }",
	},
	billingAmount: {
		id: "subscriptions.overview.billingAmount",
		defaultMessage: "Amount",
	},
	manage: {
		id: "subscriptions.overview.manage",
		defaultMessage: "Manage",
	},
	needsAttention: {
		id: "subscriptions.overview.needsAttention",
		defaultMessage: "Needs attention",
	},
	details: {
		id: "subscriptions.overview.details",
		defaultMessage: "Details",
	},
	manualRenewMessage: {
		id: "subscriptions.overview.manualRenewMessage",
		defaultMessage: "Renew now",
	},
	RenewSubscriptionPlanMessage: {
		id: "subscriptions.overview.RenewSubscriptionPlanMessage",
		defaultMessage: "Renew now",
	},
	buyNewMessage: {
		id: "subscriptions.overview.buyNewMessage",
		defaultMessage: "Buy subscription",
	},
	seeDetails: {
		id: "subscriptions.overview.seeDetails",
		defaultMessage: "See details",
	},
	paymentFailed: {
		id: "subscriptions.overview.paymentFailed",
		defaultMessage: "Payment failed",
	},
	amountOfAttentionNeeded: {
		id: "subscriptions.overview.amountOfAttentionNeeded",
		defaultMessage: "{amount} {amount, plural, one {action} other {actions}} needed",
	},
	active: {
		id: "subscriptions.overview.active",
		defaultMessage: "Active",
	},
	inactive: {
		id: "subscriptions.overview.inactive",
		defaultMessage: "Inactive",
	},
	inactiveTable: {
		id: "subscriptions.table.inactive",
		defaultMessage: "Inactive subscriptions",
	},
	suspended: {
		id: "subscriptions.overview.suspended",
		defaultMessage: "Suspended",
	},
	pendingCancellation: {
		id: "subscriptions.overview.pendingCancellation",
		defaultMessage: "Active until end of term",
	},
	expiresSoon: {
		id: "subscriptions.overview.expiresSoon",
		defaultMessage: "Expires soon",
	},
	expired: {
		id: "subscriptions.overview.expired",
		defaultMessage: "Expired",
	},
	detailsButton: {
		id: "subscriptions.overview.detailsButton",
		defaultMessage: "More details",
	},
	exVAT: {
		id: "subscriptions.overview.exVAT",
		defaultMessage: "(ex VAT)",
	},
} );
