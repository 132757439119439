import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { forEach } from "lodash";
import { Card, Title } from "@yoast/ui-library";

import Subscription from "../Subscription";

import { messages } from "./messages";
import styles from "./styles.scss";

/**
 *
 * @param {Object} props The props to use
 *
 * @returns {ReactElement} The rendered component.
 */
const Subscriptions = ( props ) => {
	const outputSubscriptionRows = () => {
		const subscriptionRows = [];
		forEach( props.subscriptions, ( subscriptionsGroupedByProduct ) => {
			subscriptionsGroupedByProduct.forEach( ( singleSubscription ) => {
				subscriptionRows.push( singleSubscription.length ? singleSubscription : [ singleSubscription ] );
			} );
		} );
		return subscriptionRows;
	};

	/**
	 * Renders the component.
	 *
	 * @returns {JSX.Element} Component.
	 * */
	return (
			<div className={ styles.subscriptions }>
				<Title as="h2" size="2">
					{ props.needsAttention && <FormattedMessage { ...messages.needsAttentionTitle } /> }
					{ ! props.needsAttention && ! props.isProvisioned && ! props.isInactive && <FormattedMessage { ...messages.subscriptionsTitle } /> }
					{ props.isProvisioned && <FormattedMessage { ...messages.partnerSubscriptionsTitle } /> }
					{ props.isInactive && <FormattedMessage { ...messages.inactiveSubscriptionsTitle } /> }
				</Title>
				{
					outputSubscriptionRows().map(
							( subscriptionsArray, i ) => {
								return (
										<Card key={ `subscription-${ i }` } className={ styles.card }>
											<Subscription
													key={ subscriptionsArray[0].id }
													subscriptionsArray={ subscriptionsArray }
													provisionerData={ props.provisionerData }
													onManage={ props.onManage }
													isProvisioned={ props.isProvisioned }
													needsAttention={ props.needsAttention }
													isInactive={ props.isInactive }
													showDetailsModal={ props.showDetailsModal }
											/>
										</Card>
								);
							}
					)
				}
			</div>
	);
};

export const SubscriptionProps = PropTypes.oneOfType( [
	PropTypes.arrayOf(
			PropTypes.shape(
					{
						id: PropTypes.string.isRequired,
						icon: PropTypes.string.isRequired,
						name: PropTypes.string.isRequired,
						used: PropTypes.number.isRequired,
						limit: PropTypes.number.isRequired,
						hasNextPayment: PropTypes.bool.isRequired,
						nextPayment: PropTypes.instanceOf( Date ).isRequired,
						hasEndDate: PropTypes.bool.isRequired,
						endDate: PropTypes.instanceOf( Date ).isRequired,
						billingAmount: PropTypes.number.isRequired,
						billingCurrency: PropTypes.string.isRequired,
						status: PropTypes.string.isRequired,
						renewalSecret: PropTypes.string.isRequired,
					},
			),
	),
	PropTypes.object,
] );

Subscriptions.propTypes = {
	subscriptions: SubscriptionProps,
	provisionerData: PropTypes.object,
	onManage: PropTypes.func.isRequired,
	needsAttention: PropTypes.bool,
	isProvisioned: PropTypes.bool,
	isInactive: PropTypes.bool,
	showDetailsModal: PropTypes.func,
};

Subscriptions.defaultProps = {
	subscriptions: [],
	provisionerData: {},
	needsAttention: false,
	isProvisioned: false,
	isInactive: false,
	showDetailsModal: null,
};

export default injectIntl( Subscriptions );
