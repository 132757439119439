import PropTypes from "prop-types";
import React from "react";
import { injectIntl, intlShape } from "react-intl";
import { SidebarNavigation } from "@yoast/ui-library";
import styles from "./styles.scss";
import { NavLink } from "react-router-dom";
import { Logo } from "../Logo";
import UserStatus from "../../containers/UserStatus";

/**
 * The main menu.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered component.
 */
const MainMenu = props => {
	return (
			<div className={ styles.sideMenu }>
				<SidebarNavigation>
					<div className={ styles.menuHeader }>
						<Logo context="sidebar" size="200px" />
						<UserStatus />
					</div>
					<SidebarNavigation.Sidebar className={ styles.menu }>
						{
							props.menuRoutes
									.filter( page => page.showInMenu )
									.map( page => {
										const title = props.intl.formatMessage( page.title );
										const label = (
												<span className={ styles.menuItem }>{ page.iconSource } { title }</span>
										);

										return page.isExternal ? (
												<SidebarNavigation.SubmenuItem
														as="a"
														id={ `main-nav__${ page.titleKey }` }
														key={ page.titleKey }
														label={ label }
														href={ page.pathname.pathname }
														target="_blank"
												/>
										) : (
												<SidebarNavigation.SubmenuItem
														as={ NavLink }
														exact
														id={ `main-nav__${ page.titleKey }` }
														key={ page.titleKey }
														label={ label }
														to={ page.path }
												/>
										);
									} )
						}
					</SidebarNavigation.Sidebar>
				</SidebarNavigation>
			</div>
	);
};

export default injectIntl( MainMenu );

MainMenu.propTypes = {
	menuRoutes: PropTypes.array.isRequired,
	intl: intlShape.isRequired,
};
