import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { Button } from "@yoast/ui-library";
import { getShopUrl } from "shared-frontend/functions/products";

const messages = defineMessages( {
	visitShop: {
		id: "suggestedActionVisitShop",
		defaultMessage: "Go to our shop",
	},
} );

export const ShopButton = ( props ) => {
	return (
			<Button
					as="a"
					href={ getShopUrl() + "/shop" }
					variant={  props.variant }
					size={ props.size }
					target="_blank"
			>
				<FormattedMessage { ...messages.visitShop } />
			</Button>
	);
};
