import { defineMessages } from "react-intl";

export const messages = defineMessages( {
	paymentDetailsTitle: {
		id: "subscriptionPage.paymentDetails.title",
		defaultMessage: "Payment details",
	},
	startDate: {
		id: "subscriptionPage.paymentDetails.startDate",
		defaultMessage: "Start date",
	},
	billing: {
		id: "subscriptionPage.paymentDetails.billing",
		defaultMessage: "Billing",
	},
	nextBilling: {
		id: "subscriptionPage.paymentDetails.nextBilling",
		defaultMessage: "Next billing",
	},
	paymentMethod: {
		id: "subscriptionPage.paymentDetails.paymentMethod",
		defaultMessage: "Payment method",
	},
	invoicesTitle: {
		id: "subscriptionPage.invoices.title",
		defaultMessage: "Invoices",
	},
	downloadTitle: {
		id: "subscriptionPage.download.title",
		defaultMessage: "Included products",
	},
	downloadLinkText: {
		id: "subscriptionPage.download.linkText",
		defaultMessage: "Download",
	},
	installationGuide: {
		id: "subscriptionPage.installationGuide",
		defaultMessage: "Read our installation guides",
	},
	yoastCoursesTitle: {
		id: "subscriptionPage.yoastCoursesTitle",
		defaultMessage: "Yoast SEO academy",
	},
	yoastPluginsTitle: {
		id: "subscriptionPage.yoastPluginsTitle",
		defaultMessage: "Yoast SEO plugins",
	},
	connectedSites: {
		id: "connectedSites.title",
		defaultMessage: "Connected sites",
	},
	noConnectedSites: {
		id: "noConnectedSites.message",
		defaultMessage: "There are no connected sites to this subscription.",
	},
	title: {
		id: "subscription.title",
		defaultMessage: "",
	},
	subtitle: {
		id: "subscription.subtitle",
		defaultMessage: "",
	},
	subscriptionDetailsTitle: {
		id: "subscription.detailsTitle",
		defaultMessage: "Subscription details",
	},
	subscriptionNumber: {
		id: "subscription.number",
		defaultMessage: "Number",
	},
	subscriptionStatus: {
		id: "subscription.status",
		defaultMessage: "Status",
	},
	subscriptionActionTitle: {
		id: "subscription.actionTitle",
		defaultMessage: "Subscription actions",
	},
	orderDate: {
		id: "order.date",
		defaultMessage: "Date",
	},
	orderId: {
		id: "order.id",
		defaultMessage: "Order",
	},
	orderItems: {
		id: "order.items",
		defaultMessage: "Items",
	},
	orderTotal: {
		id: "order.total",
		defaultMessage: "Total",
	},
	orderStatus: {
		id: "order.status",
		defaultMessage: "Status",
	},
	invoiceButton: {
		id: "subscriptionDetails.buttons.invoice",
		defaultMessage: "Invoice",
	},
	cancelLink: {
		id: "subscriptionDetails.buttons.cancel",
		defaultMessage: "Cancel subscription",
	},
	cancelPending: {
		id: "subscriptionDetails.cancelPending",
		defaultMessage: "Your subscription has been cancelled.",
	},
	extendSubscriptionLabel: {
		id: "subscriptionDetails.extendSubscriptionLabel",
		defaultMessage: "Renew subscription",
	},
	switchPayentMethodLabel: {
		id: "subscriptionDetails.extendSubscriptionLabel",
		defaultMessage: "Use another payment method for your next renewal",
	},
	extendSubscription: {
		id: "subscriptionDetails.extendSubscription",
		defaultMessage: "Renew subscription",
	},
	switchPaymentMethod: {
		id: "subscriptionDetails.label.switchPaymentMethod",
		defaultMessage: "Switch payment method",
	},
	addSiteToSubscription: {
		id: "subscriptionDetails.label.addSite",
		defaultMessage: "Add site",
	},
	cannotAddSiteToSubscription: {
		id: "subscriptionDetails.cant.addSite",
		defaultMessage: "You cannot add a site to a subscription that lasts more than five years",
	},
	addSiteToSubscriptionLink: {
		id: "subscriptionDetails.buttons.addSiteLink",
		defaultMessage: "Add site to this subscription",
	},
	upgradeToBundleLabel: {
		id: "subscriptionDetails.label.upgradeToBundle",
		defaultMessage: "Upgrade to bundle",
	},
	upgradeToBundleLink: {
		id: "subscriptionDetails.link.upgradeToBundle",
		defaultMessage: "Upgrade subscription to bundle",
	},
	switchToAutomaticLabel: {
		id: "subscriptionDetails.switchToAutomaticLabel",
		defaultMessage: "Automatic renewal",
	},
	switchToAutomatic: {
		id: "subscriptionDetails.switchToAutomatic",
		defaultMessage: "Enable automatic payments for future renewals",
	},
} );
