import React, { Fragment } from "react";

import SubNavigation, { SubNavigationRoutes } from "./SubNavigation";
import ProfilePageContainer from "../containers/ProfilePage";
import OrderPage from "../containers/OrdersPage";

/**
 * Returns the rendered Account Page component.
 *
 * @returns {ReactElement} The rendered account page.
 */
const AccountPage = () => {
	const itemRoutes = [
		{
			component: OrderPage,
			path: "/account/orders",
			title: "Orders",
		},
		{
			component: ProfilePageContainer,
			path: "/account/profile",
			title: "Profile",
		},
	];

	// This doesn't fit in the itemRoutes because SubNavigation also uses this array and we don't want an empty menuItem.
	const defaultRoute = {
		component: OrderPage,
		path: "/account",
	};

	return (
		<Fragment>
			<SubNavigation itemRoutes={ itemRoutes } />
			<SubNavigationRoutes itemRoutes={ [ defaultRoute, ...itemRoutes ] } />
		</Fragment>
	);
};

export default AccountPage;
