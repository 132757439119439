// External dependencies.
import React, { Component } from "react";
import styled from "styled-components";

// Internal dependencies.
import UserStatus from "../../containers/UserStatus";
import Menu from "../../components/Menu";
import DebugInfo from "../../components/DebugInfo";
import { Logo } from "../../components/Logo";

import styles from "./styles.scss";

const Sidebar = ( props ) => {
  return (
    <div className={ styles.sidebar }>
      <Menu menuRoutes={ props.menuItems } />
      <DebugInfo />
    </div>
  );
}

export default Sidebar;
