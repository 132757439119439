import { defineMessages } from "react-intl";

export const messages = defineMessages( {
	needsAttentionTitle: {
		id: "needs.attention.title",
		defaultMessage: "Needs attention",
	},
	subscriptionsTitle: {
		id: "subscriptions.title",
		defaultMessage: "Subscriptions",
	},
	partnerSubscriptionsTitle: {
		id: "partner.subscriptions.title",
		defaultMessage: "Partner subscriptions",
	},
	inactiveSubscriptionsTitle: {
		id: "inactive.subscriptions.title",
		defaultMessage: "Inactive subscriptions",
	},
} );
