import React, { useState, useContext } from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import { browserName } from "react-device-detect";
import { InstallContext } from "./InstallContext";
import CheckoutLadyImage from "../../icons/checkout-lady.svg";
import CheckoutSuccessImage from "../../icons/checkout-success.svg";
import LogoImage from "../../icons/Yoast-logo.svg";
import useRequest from "../../reactHooks/useRequest";
import classNames from "classnames";
import { Alert, Button, Link, Title } from "@yoast/ui-library";
import { NavLink } from "react-router-dom";
import styles from "./InstallStyles.scss";

const messages = defineMessages( {
	checkout: {
		id: "downloading.checkout",
		defaultMessage: "Checkout",
	},
	stepOne: {
		id: "downloading.stepOne",
		defaultMessage: "Enter your details",
	},
	stepTwo: {
		id: "downloading.stepTwo",
		defaultMessage: "Pay",
	},
	stepThree: {
		id: "downloading.stepThree",
		defaultMessage: "Receive your products",
	},
	stepFour: {
		id: "downloading.stepFour",
		defaultMessage: "Install",
	},
	title: {
		id: "downloading.title",
		defaultMessage: "Downloading...",
	},
	subheaderTitle: {
		id: "downloading.subheaderTitle",
		defaultMessage: "Need help?",
	},
	orderSuccess: {
		id: "downloading.orderSuccess",
		defaultMessage: "Your order has been processed successfully!",
	},
	nextSteps1: {
		id: "downloading.nextstep.firstLine",
		defaultMessage: "Yoast SEO Premium is being downloaded. After the file successfully downloads to your computer, " +
			"you can proceed to your website where you can install and activate your purchase.",
	},
	goToSiteButton: {
		id: "downloading.goToSiteButton",
		defaultMessage: "I'm ready. Please take me to my website",
	},
	needHelp: {
		id: "downloading.needHelp",
		defaultMessage: "Check out the video below where we'll walk you through the final steps of installing " +
		"and activating Yoast SEO Premium for your website.",
	},
	videoTitle: {
		id: "downloading.videoTitle",
		defaultMessage: "Installing your plugin",
	},
	siteSelectedForInstallation: {
		id: "downloading.siteSelectedForInstallation",
		defaultMessage: "You've selected a site to install your products on, use the install button to continue.",
	},
	myYoastLink: {
		id: "downloading.myYoastLink",
		defaultMessage: "your MyYoast account.",
	},
	installOtherPluginsInfoAlert: {
		id: "downloading.installOtherPluginsInfoAlert",
		defaultMessage: "This will only install {bold}. If you also want to install other Yoast plugins, find and download them from {link}",
	},
	seoPremium: {
		id: "downloading.seoPremium",
		defaultMessage: "Yoast SEO Premium",
	},

} );

const myYoastLink = <Link as={ NavLink } to={ "/" } id="front-page">
	<FormattedMessage { ...messages.myYoastLink } />
</Link>;

const boldSEOPremium = <strong>
	<FormattedMessage { ...messages.seoPremium } />
</strong>;

/**
 * Renders the downloading component. Which shows instructions about installing Yoast plugins.
 *
 * @param {object} props The properties.
 *
 * @returns {ReactComponent} The rendered component.
 */
function Downloading( props ) {
	const { downloadZip, linkedSite, invoiceNumberParam } = useContext( InstallContext );

	const url = invoiceNumberParam ? `Orders/productGroups/${invoiceNumberParam}` : "";
	const { data: productGroups } = useRequest( url, "GET", {} );

	const plugins = productGroups.filter( productGroup => productGroup.parent && productGroup.parent.slug === "all-plugins" );

	/**
	 * Redirects the user to the upload plugin zip page on the selected site.
	 *
	 * @returns {void}
	 */
	function goToSite() {
		const customerSite = linkedSite.replace( /\/$/, "" );

		window.location = customerSite + "/wp-admin/plugin-install.php?tab=upload";
	}

	/**
	 * Returns the youtube embed url for the video intended for the user's browser.
	 *
	 * @returns {string} The youtube embed url.
	 */
	function getVideoForBrowser() {
		const wistiaIds = {
			Chrome: "wogh6vsr21",
			Safari: "wogh6vsr21",
			Firefox: "wogh6vsr21",
			Edge: "wogh6vsr21",
			"default": "wogh6vsr21",
		};

		let wistiaId = wistiaIds[ browserName ];

		if ( ! wistiaId ) {
			wistiaId = wistiaIds.default;
		}

		return `https://fast.wistia.net/embed/iframe/${ wistiaId }?dnt=1&autoPlay=false`;
	}

	const [ wistiaUrl ] = useState( () => getVideoForBrowser() );

	return (
		<div className={ styles.installWrapper }>
			<div className={ styles.header }>
				<div className={ classNames( styles.headerContainer, styles.wideContent ) }>
					<div className={ styles.headerLogo }>
						<img src={ LogoImage } alt="Yoast SEO for everyone" />
						<p>
							<FormattedMessage { ...messages.checkout } />
						</p>
					</div>
					<img
						className={ styles.headerImage }
						src={ CheckoutLadyImage }
						alt=""
					/>
				</div>
			</div>

			<div className={ classNames( styles.steps, styles.wideContent ) }>
				<div className={ styles.step }><FormattedMessage { ...messages.stepOne } /></div>
				<div className={ styles.step }><FormattedMessage { ...messages.stepTwo } /></div>
				<div className={ styles.step }><FormattedMessage { ...messages.stepThree } /></div>
				<div className={ classNames( styles.step, styles.activeStep ) }><FormattedMessage { ...messages.stepFour } /></div>
			</div>

			<div className={ classNames( styles.narrowContent, styles.textCenter, styles.ySpacing ) }>
				<img
					className={ styles.successImage }
					src={ CheckoutSuccessImage }
					alt={ props.intl.formatMessage( messages.orderSuccess ) }
				/>

				<div>
					<Title
						as="h1"
						size="2"
					>
						<FormattedMessage { ...messages.title } />
					</Title>
					<p><FormattedMessage { ...messages.nextSteps1 } /></p>
				</div>

				<Button
					id="install-addon-redirect-action"
					className="install-addon-redirect-button"
					onClick={ goToSite }
				>
					<FormattedMessage{ ...messages.goToSiteButton } />
				</Button>

				{ ( ! invoiceNumberParam || plugins.length > 1 ) && (
					<Alert variant="info">
						<FormattedMessage
							{ ...messages.installOtherPluginsInfoAlert }
							values={ { link: myYoastLink, bold: boldSEOPremium } }
						/>
					</Alert>
				) }

				<div>
					<Title
						as="h2"
						size="2"
					>
						<FormattedMessage { ...messages.subheaderTitle } />
					</Title>
					<p><FormattedMessage { ...messages.needHelp } /></p>
				</div>

				<iframe
					className={ styles.iframe }
					title={ messages.videoTitle.defaultMessage }
					width="500"
					height="250"
					src={ wistiaUrl }
					allowFullScreen={ true }
					frameBorder="0"
				/>

				<iframe
					title="download"
					width="0"
					height="0"
					src={ downloadZip }
					style={ { display: "none" } }
				/>

			</div>
		</div>
	);
}

Downloading.propTypes = {
	intl: intlShape.isRequired,
};

export default injectIntl( Downloading );
